html {
  height: 100%;
}

body {
  height: inherit;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root,
#app {
  height: 100%;
}

#hold {
  padding: 50px;
  margin: auto;
}

#head {
  color: #fff;
  word-wrap: break-word;
  border-bottom: 2px solid #424244;
  margin: 0% 1%;
}

.title {
  font-family: 'D-DIN', sans-serif;
  font-weight: 700;
  font-size: 1.666rem;
  font-weight: 100;
}

.orderType {
  font-size: 1.5rem;
  font-weight: 300;
  float: right;
  margin-top: 26px;
}

.page {
  float: right;
}

p.info {
  font-size: 1.2rem;
  padding: 12px;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

#foot {
  color: white;
  height: 40px;
  border-top: 1px solid #474747;
}

#content {
  display: inline-block;
  max-width: 100%;
  margin: 20px 0px;
  padding: 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust minmax() for desired minimum column width */
  font-size: 4em;
  margin: 0 auto;
  padding: 0;
  user-select: none;
  max-height: 1920px;
}

/* Default two-column layout for portrait screens */
.order {
  color: #fff;
  float: left;
  width: calc(50% - 2%); /* Two columns */
  margin: 1%;
  padding: 0% 1%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  border: 2px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
}

/* Media query for landscape screens */
@media screen and (min-width: 1080px) { /* Adjust 768px to your preferred breakpoint */
  .order {
    width: calc(25% - 2%); /* Four columns */
  }
}

/* Clearfix for the floating elements */
#content:after {
  content: "";
  display: table;
  clear: both;
}

.order.managerMode {
  border: 1px solid #fff;
  border-radius: 8px;
  padding-left: 10px;
}

a {
  text-decoration: none;
  color: #d9d9d9;
}

a:link {
  text-decoration: none;
  color: #d9d9d9;
}

a:visited {
  text-decoration: none;
  color: #d9d9d9;
}

a:hover {
  text-decoration: none;
  color: #d9d9d9;
}

a:active {
  text-decoration: none;
  color: #d9d9d9;
}

#config {
  color: black;
  background-color: white !important;
  border-radius: 10px;
  position: absolute;
  border: 1px solid #efefef;
  top: 470px;
  padding: 30px;
  margin: auto;
  width: 488px;
  height: 474px;
  overflow-y: scroll;
}
